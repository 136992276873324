import { components } from 'App';

/**
 * Promise wrapper around the `App.components.doAjax` method
 *
 * @param  {Object} options The options for the AJAX request
 * @return {Promise}        A promise resolving or failing
 */
export default (options) => new Promise((resolve, reject) => {
  components.doAjax(options, {
    onDone(response) {
      // TODO : handles basic response error before resolving
      resolve(response);
    },
    onFail: reject,
  });
});
