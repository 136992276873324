import { prestashop } from 'window';
import { i18n, components, bus } from 'App';
import globalFetch from '~/utils/fetch';
import { log } from './utils/debug';
import { l } from './utils/i18n';
import * as types from './mutation-types';

/**
 * Default fetch options
 * @type {Object}
 */
const defaultOptions = {
  url: '/',
  dataType: 'json',
  method: 'POST',
  data: {
    fc: 'module',
    module: 'meta_endpointsmanager',
    controller: 'endpoints',
    action: 'cart',
    method: 'help',
    /* eslint-disable-next-line */
    id_lang: i18n.id,
  },
};

/**
 * Local fetch function mergin custom and local default options
 *
 * @param  {Object}  options The custom options to pass to the function
 * @return {Promise}         A promise resolving on done, rejecting on fail
 */
async function fetch(dataOptions, skipErrors = false) {
  let response = null;

  try {
    response = await globalFetch({
      ...defaultOptions,
      data: {
        ...defaultOptions.data,
        ...dataOptions,
      },
    });
  } catch (err) {
    components.alert.error(l('There was an unexpected error, try again later.'), 5);
    return response;
  }

  if (!response) {
    components.alert.error(l('There was an unexpected error, try again later.'), 5);
    return response;
  }

  if (response.code !== 200 && !skipErrors) {
    if (response.messages.length) {
      components.alert.error(response.messages.join('<br>'), 0);
    }
    return null;
  }

  if (response.result.static_token) {
    prestashop.modules.meta_frontsettings.CONFIG.STATIC_TOKEN = response.result.static_token;
  }

  if (response.result.token) {
    prestashop.modules.meta_frontsettings.CONFIG.TOKEN = response.result.token;
  }

  return response;
}

export default {
  /**
   * Get the current cart
   *
   * @param  {Vuex}   store The current store instance
   * @return {void}
   */
  async getCart(store) {
    log('action', 'getCart');
    const response = await fetch({ method: 'get' });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/getCart', response.result.cart);
    return response;
  },
  /**
   * Add the given product to the cart
   *
   * @param {Vuex}   store   The current store instance
   * @param {Number} product The ID of the product to add
   * @return {void}
   */
  async addProduct(store, id) {
    log('action', 'addProduct', id);
    /* eslint-disable-next-line camelcase */
    const [ id_product, id_product_attribute, selected_size ] = id.split('-');
    const response = await fetch({
      method: 'addProduct',
      /* eslint-disable-next-line camelcase */
      id_product,
      /* eslint-disable-next-line camelcase */
      id_product_attribute,
    });

    const nice_id_product = `${id_product}-${id_product_attribute}`;

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/addProduct', { payload: nice_id_product, data: response.result.cart });

    if (store.state.products[nice_id_product]) {
      const addedProduct = store.state.products[nice_id_product];
      const quantity = 1;

      const MetaDatalayer = window.dataLayer ?? [];

      var d = new Date();

      var month = d.getMonth()+1;
      var day = d.getDate();

      const timestamp = d.getFullYear() + '-' +
          (month<10 ? '0' : '') + month + '-' +
          (day<10 ? '0' : '') + day;

      MetaDatalayer.push({
        'ecommerce': null
      });

      MetaDatalayer.push({
        event: 'add_to_cart',
        timestamp: timestamp,
        ecommerce: {
          currency: window.prestashop.currency.iso_code || 'EUR',
          items: [
            {
              item_id: addedProduct.datalayer.item_id,
              item_name: addedProduct.datalayer.item_name,
              item_category: addedProduct.datalayer.item_category,
              item_category2: addedProduct.datalayer.item_category2,
              item_category3: addedProduct.datalayer.item_category3,
              item_category4: addedProduct.datalayer.item_category4,
              item_category5: selected_size ? selected_size : '',
              item_variant: addedProduct.datalayer.item_variant,
              discount: addedProduct.reduction,
              price: addedProduct.price_without_reduction,
            },
          ],
        }
      });
    }
    return response;
  },

  /**
   * Delete the given product from the cart
   *
   * @param  {Vuex}   store The current store instance
   * @param  {String} id    The ID of the product to delete
   * @return {void}
   */
  async deleteProduct(store, id) {
    log('action', 'deleteProduct', id);
    /* eslint-disable-next-line camelcase */
    const [ id_product, id_product_attribute ] = id.split('-');

    const response = await fetch({
      method: 'deleteProduct',
      /* eslint-disable-next-line camelcase */
      id_product,
      /* eslint-disable-next-line camelcase */
      id_product_attribute,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/deleteProduct', {
      payload: id,
      data: response.result.cart,
    });

    return response;
  },

  /**
   * Update a product's quantity
   *
   * @param  {Vuex}   store            The current store instance
   * @param  {String} options.id       The ID of the product
   * @param  {Number} options.quantity The new quantity of the product
   * @return {void}
   */
  async updateProductQuantity(store, { id, quantity }) {
    log('action', 'updateProductQuantity', id);
    /* eslint-disable-next-line camelcase */
    const [ id_product, id_product_attribute ] = id.split('-');
    const response = await fetch({
      method: 'updateQuantity',
      /* eslint-disable-next-line camelcase */
      id_product,
      /* eslint-disable-next-line camelcase */
      id_product_attribute,
      quantity,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/updateProductQuantity', {
      payload: { id, quantity },
      data: response.result.cart,
    });
    return response;
  },

  /**
   * Add discount to the cart
   *
   * @param  {Vuex}   store The current store instance
   * @param  {String} code  The code of the discount to add
   * @return {void}
   */
  async addDiscount(store, code) {
    log('action', 'addDiscount', code);

    const response = await fetch({
      method: 'addCartRule',
      code,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/addDiscount', {
      payload: code,
      data: response.result.cart,
    });
    return response;
  },

  /**
   * Remove discount from the cart
   *
   * @param  {Vuex}   store The current store instance
   * @param  {Number} id    The ID of the discount to add
   * @return {void}
   */
  async deleteDiscount(store, id) {
    log('action', 'deleteDiscount', id);

    const response = await fetch({
      method: 'deleteCartRule',
      /* eslint-disable-next-line camelcase */
      id_cart_rule: id,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/deleteDiscount', {
      payload: id,
      data: response.result.cart,
    });
    return response;
  },

  /**
   * Update the current cart billing address
   *
   * @param  {Vuex}   store   The current store instance
   * @param  {Number} id      The new billing address' ID
   * @return {void}
   */
  async updateBillingAddress(store, id) {
    log('action', 'updateBillingAddress', id);

    const response = await fetch({
      method: 'updateBillingAddress',
      /* eslint-disable-next-line camelcase */
      id_address: id,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/updateBillingAddress', {
      payload: id,
      data: response.result.cart,
    });
    return response;
  },

  /**
   * Update the current cart shipping address
   *
   * @param  {Vuex}   store   The current store instance
   * @param  {Number} address The new shipping address' ID
   * @return {void}
   */
  async updateShippingAddress(store, id) {
    log('action', 'updateShippingAddress', id);

    const response = await fetch({
      method: 'updateShippingAddress',
      /* eslint-disable-next-line camelcase */
      id_address: id,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/updateShippingAddress', {
      payload: id,
      data: response.result.cart,
    });

    // Always update the default delivery method after a shipping address update
    store.dispatch('updateDeliveryMethod', store.getters.defaultDeliveryMethod);
    return response;
  },

  /**
   * Update the current cart delivery method
   * @param  {Vuex}   store The current store instance
   * @param  {Number} id    The ID of the selected delivery method
   * @return {void}
   */
  async updateDeliveryMethod(store, id) {
    log('action', 'updateDeliveryMethod', id);

    const carriers = [ id, '' ].join(',');
    const option = {};
    option[store.state.shippingAddress.id] = carriers;

    const response = await fetch({
      method: 'updateDeliveryMethod',
      /* eslint-disable-next-line camelcase */
      delivery_option: JSON.stringify(option),
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/updateDeliveryMethod', {
      payload: id,
      data: response.result.cart,
    });
    return response;
  },

  /**
   * Update the Term Of Service value of the cart
   *
   * @param  {Vuex}    store     The current store instance
   * @param  {Boolean} isChecked The TOS value
   * @return {Object}            The response object
   */
  async updateTermOfService(store, isChecked) {
    log('action', 'updateTermOfService', isChecked);

    const response = await fetch({
      method: 'updateTermOfService',
      checked: Number(isChecked),
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/updateTermOfService', {
      payload: isChecked,
      data: response.result.cart,
    });

    const { meta_nonstoreretail } = window.prestashop.modules;

    await store.dispatch('getPaymentMethods', meta_nonstoreretail && meta_nonstoreretail.get_non_store_retail_payment_methods);
    return response;
  },

  /**
   * Update the gift data of the cart
   *
   * @param  {Vuex}    store              The current store instance
   * @param  {Boolean} options.gift       Cart is a gift.
   * @param  {Boolean} options.recyclable Gift recyclable allowed.
   * @param  {String}  options.message    Gift message.
   * @return {Object}                     The response object
   */
  async updateGiftData(store, { gift, message = null }) {
    log('action', 'updateGiftData', gift, message);

    const response = await fetch({
      action: 'gift_wrapping',
      method: 'updateGift',
      gift,
      message,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_CART, response.result.cart);
    bus.$emit('cart/updateGiftData', {
      payload: { gift, message },
      data: response.result.cart,
    });

    return response;
  },

  /**
   * Get the cart payment method and/or possible errors
   *
   * @param  {Vuex}   store The current store instance
   * @return {Object}       The response object
   */
  async getPaymentMethods(store, getNonStoreRetailPaymentMethods = false) {
    log('action', 'getPaymentMethods');

    const params = {
      method: 'getPaymentMethods',
    };

    if (getNonStoreRetailPaymentMethods) {
      params.get_non_store_retail_payment_methods = 1;
    }

    const response = await fetch(params);

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_PAYMENT_METHODS_ERRORS, response.messages);
    store.commit(types.UPDATE_PAYMENT_METHODS, response.result.paymentMethods);
    bus.$emit('cart/getPaymentMethods', {
      payload: null,
      data: response.result.paymentMethods,
    });
    return response;
  },

  /**
   * Get the term of service content
   *
   * @param  {Vuex}   store The current store instance
   * @return {String}       The content of the page
   */
  async getTermOfServiceContent(store) {
    log('action', 'getTermOfServiceContent');

    if (store.state.termOfServiceContent) {
      return store.state.termOfServiceContent;
    }

    /* eslint-disable camelcase */
    const response = await fetch({
      action: 'CMS',
      method: 'getPage',
      id_cms: 3,
    });

    if (!response) {
      return response;
    }

    store.commit(types.UPDATE_TOS_CONTENT, response.result.cms.content);
    bus.$emit('cart/getTermOfServiceContent', {
      payload: null,
      data: response.result.cms,
    });
    return response;
  },

  /**
   * Get the croll-sell
   *
   * @param  {Vuex}   store The current store instance
   * @return {String}       The content of the page
   */
  async getCrossSell() {
    log('action', 'getCrossSell');

    /* eslint-disable camelcase */
    const response = await fetch({
      method: 'getCrossSell',
    });

    if (!response) {
      return response;
    }

    return response;
  },
};
