/* =============================================================================
 * Cart mutation types
 * ========================================================================== */

/**
 * Update the cart
 * @type {String}
 */
export const UPDATE_CART = 'UPDATE_CART';

/**
 * Update the gift message
 * @type {String}
 */
export const UPDATE_GIFT_MESSAGE = 'UPDATE_GIFT_MESSAGE';

/**
 * Update the recyclable
 * @type {String}
 */
export const UPDATE_RECYCLABLE = 'UPDATE_RECYCLABLE';

/**
 * Update payment method for the cart
 * @type {String}
 */
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';

/**
 * Update payment method errors
 * @type {String}
 */
export const UPDATE_PAYMENT_METHODS_ERRORS = 'UPDATE_PAYMENT_METHODS_ERRORS';

/**
 * Update the Term of Service content
 * @type {String}
 */
export const UPDATE_TOS_CONTENT = 'UPDATE_TOS_CONTENT';
