import * as types from './mutation-types';
import { log } from './utils/debug';

export default {
  /**
   * Update the entire cart state
   *
   * @param  {Object} state The current store's state
   * @param  {Object} cart  The new cart
   * @return {Object}       The new state object
   */
  [types.UPDATE_CART](state, cart) {
    log('mutation', types.UPDATE_CART, cart);

    return Object.keys(cart).reduce((newState, key) => {
      if (key in newState) {
        newState[key] = cart[key];
      }
      return newState;
    }, state);
  },

  /**
   * Update the cart gift message value
   *
   * @param  {Object}  state        The current store's state
   * @param  {Boolean} gift_message The gift message value
   * @return {Object}               The new state object
   */
  [types.UPDATE_GIFT_MESSAGE](state, giftMessage) {
    log('mutation', types.UPDATE_GIFT_MESSAGE, giftMessage);
    state.gift.message = giftMessage;
    return state;
  },

  /**
   * Update the cart recyclable value
   *
   * @param  {Object}  state      The current store's state
   * @param  {Boolean} recyclable The recyclable value
   * @return {Object}             The new state object
   */
  [types.UPDATE_RECYCLABLE](state, recyclable) {
    log('mutation', types.UPDATE_RECYCLABLE, recyclable);
    state.gift.recyclable = recyclable;
    return state;
  },

  /**
   * Update the cart payment method
   *
   * @param  {Object} state         The current store's state
   * @param  {Object} paymentMethods The payment methods object
   * @return {Object}               The new state object
   */
  [types.UPDATE_PAYMENT_METHODS](state, paymentMethods) {
    log('mutation', types.UPDATE_PAYMENT_METHODS, [ paymentMethods ]);
    state.paymentMethods = paymentMethods;
    return state;
  },

  /**
   * Update the cart payment method
   *
   * @param  {Object} state               The current store's state
   * @param  {Object} paymentMethodsErrors The payment methods object
   * @return {Object}                     The new state object
   */
  [types.UPDATE_PAYMENT_METHODS_ERRORS](state, paymentMethodsErrors) {
    log('mutation', types.UPDATE_PAYMENT_METHODS_ERRORS, paymentMethodsErrors);
    state.paymentMethodsErrors = paymentMethodsErrors;
    return state;
  },

  /**
   * Update the Term of Service content
   *
   * @param  {Object} state                The current store's state
   * @param  {String} termOfServiceContent The content
   * @return {Object}                      The new state object
   */
  [types.UPDATE_TOS_CONTENT](state, termOfServiceContent) {
    log('mutation', types.UPDATE_TOS_CONTENT, termOfServiceContent);
    state.termOfServiceContent = termOfServiceContent;
    return state;
  },
};
