export default {
  /**
   * Get the default delivery method ID (filtered by best price)
   * @type {Number}
   */
  defaultDeliveryMethod({ deliveryOptions }) {
    let carrier = null;

    Object.keys(deliveryOptions).forEach((id) => {
      const option = deliveryOptions[id];
      Object.keys(option).forEach((key) => {
        const ids = key.split(',').filter((str) => str);
        if (option[key].is_best_price) {
          carrier = Number(ids.shift());
        }
      });
    });

    return carrier;
  },

  /**
   * Get the selected carrier object
   *
   * @param  {Object} state The current state
   * @return {Object}       The object of the currently selected carrier
   */
  selectedCarrier(state) {
    const { deliveryMethod: id, deliveryOptions } = state;
    let selectedOption = null;

    Object.keys(deliveryOptions).forEach((idAdress) => {
      const option = deliveryOptions[idAdress];
      Object.keys(option).forEach((key) => {
        const ids = key
          .split(',')
          .filter((str) => str)
          .map((str) => Number(str));
        if (ids.includes(id)) {
          selectedOption = option[key].carrier_list[id];
        }
      });
    });

    return selectedOption;
  },

  /**
   * Do we display the shipping cost?
   * In order to have a "without shipping cost estimate" when the user is not
   * logged in, we provide a getters to test if the totalShipping is 0 or if the
   * user is logged in (to display shipping cost event when they are free).
   *
   * @param  {Object}  state       The cart module state
   * @param  {Object}  getters     The cart module getters
   * @param  {Object}  rootState   The store root state
   * @param  {Object}  rootGetters The store root getters
   * @return {Boolean}             Whether to display the shipping costs or not
   */
  shouldDisplayShippingCost(state, getters, rootState, rootGetters) {
    return state.prices.totalShipping > 0 || rootGetters['user/isLogged'];
  },
};
